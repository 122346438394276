<template>
  <div class="flip-container">
    <div class="login-screen">
      <span class="main-logo" :style="{ backgroundImage: `url(${config.SITE_LOGO_URL})` }"></span>
      <div v-if="!success" class="login-block row middle-xs">
        <span
          class="loginimg"
          :style="{ backgroundImage: `url(${config.LOGIN_PAGE_IMAGE_URL})` }"
        ></span>
        <div v-if="isSignupLoading" class="load-row">
          <svg class="spinner" width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="path"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
        <div v-if="!isSignupLoading" class="signup-cont">
          <div class="alreadylogin-label">
            Already have an account?
            <k-link :to="{ path: '/login?app=' + appId + '&mode=fp' }" class="signin-text"
              >Sign in</k-link
            >
          </div>
          <div class="login-info signup-info">
            <span class="ftitle">Sign Up</span>
            <div class="signup-info-row">
              <div class="signup-info-row-column1">
                <label class="flabel">Full Name</label>
                <k-text-input
                  ref="full_name"
                  class="login-input"
                  :required="false"
                  :autofocus="true"
                  @change="(val) => onInputValueChange(val, valueType.FULL_NAME)"
                  @validation="onValidation"
                  @keyup="onKeyUp"
                />
              </div>
              <div class="signup-info-row-column2">
                <label class="flabel">Email</label>
                <k-text-input
                  ref="email"
                  class="login-input"
                  :required="false"
                  :default-focus="true"
                  type="email"
                  @change="(val) => onInputValueChange(val, valueType.EMAIL)"
                  @validation="onValidation"
                  @keyup="onKeyUp"
                />
              </div>
            </div>
            <div class="signup-info-row">
              <div class="signup-info-row-column1">
                <label class="flabel">Company</label>
                <k-text-input
                  ref="company"
                  class="login-input"
                  :required="false"
                  :default-focus="true"
                  type="text"
                  @change="(val) => onInputValueChange(val, valueType.COMPANY)"
                  @validation="onValidation"
                  @keyup="onKeyUp"
                />
              </div>
              <div class="signup-info-row-column2">
                <label class="flabel">Advertiser Domain </label>
                <k-icon-tooltip
                  :icon="['fas', 'info-circle']"
                  tooltip="This is the name of the brand you wish to advertise"
                />
                <k-text-input
                  ref="advertiser_domain"
                  class="login-input"
                  :required="false"
                  :default-focus="true"
                  type="text"
                  @change="(val) => onInputValueChange(val, valueType.ADVERTISER_DOMAIN)"
                  @validation="onValidation"
                  @keyup="onKeyUp"
                />
              </div>
            </div>
            <div class="signup-info-row">
              <div class="signup-info-row-column1">
                <label class="flabel">Password</label>
                <k-text-input
                  ref="password"
                  class="login-input"
                  :required="false"
                  :default-focus="true"
                  type="password"
                  @change="(val) => onInputValueChange(val, valueType.PASSWORD)"
                  @validation="onValidation"
                  @keyup="onKeyUp"
                />
              </div>
              <div class="signup-info-row-column2">
                <label class="flabel">Confirm Password</label>
                <k-text-input
                  ref="confirm_password"
                  class="login-input"
                  :required="false"
                  :default-focus="true"
                  type="password"
                  @change="(val) => onInputValueChange(val, valueType.CONFIRM_PASSWORD)"
                  @validation="onValidation"
                  @keyup="onKeyUp"
                />
              </div>
            </div>
            <div class="agree-label">
              <k-checkbox name="" :checked="tncAccepted" @selected="onSelectTnc" />
              I accept Digital Remedy's
              <a
                class="tandcLabel"
                href="https://www.digitalremedy.com/terms-and-conditions/"
                target="_blank"
                >Terms & Conditions</a
              >
            </div>
            <k-button :size="4" label="Sign Up" @click="onClickSignup" />
          </div>
        </div>
        <div v-if="error" class="error">Signup Error: {{ error }}</div>
      </div>

      <div v-else class="login-block login-block-verify row middle-xs">
        <span
          class="loginimg"
          :style="{ backgroundImage: `url(${config.LOGIN_PAGE_IMAGE_URL})` }"
        ></span>
        <div class="login-info">
          <font-awesome-icon class="resend-email-icon" :icon="['far', 'envelope-open']" size="3x" />
          <p class="f-resend-title">Please verify your email</p>
          <p class="finfo">You're almost there! we sent an email to</p>
          <p class="femail">{{ email }}</p>
          <p class="finfo mb-15">
            Click the link in that email to complete the registration. If you don't see it, you may
            need to check your <span>spam</span> folder.
          </p>
          <p class="finfo mb-10">Still can't find the email?</p>
          <p v-if="showEmailSent" class="sent-email-success">
            <font-awesome-icon class="success-check-icon" :icon="['fas', 'check']" size="1x" />
            Invite sent!
          </p>
          <k-button
            v-if="!showEmailSent"
            :size="4"
            label="Resend Invite"
            @click="sendEmailVerification"
          />
          <div class="finfo">
            Need help?
            <a href="mailto:flipss@digitalremedy.com" class="link-color" target="_blank"
              >Contact us</a
            >
          </div>
        </div>

        <div v-if="error" class="error">Error: {{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { isEqual } from 'lodash';
import { isBlank } from 'adready-api/helpers/common';
import VueCookies from 'vue-cookies';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import { COOKIE_NAME_REDIRECT_URI } from 'adready-api/helpers/cookie-session-helper';
import config from '~/config';

export default {
  components: {
    KLink: () =>
      import(/* webpackChunkName: "k-link" */ 'adready-vue/components/elements/k-link.vue'),
  },

  mixins: [validationsMixin.events],
  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      signUp: {},
      errorMsg: null,
      focusVar: null,
      tncAccepted: false,
      appId: null,
      isSignupLoading: false,
      success: false,
      email: '',
      userId: '',
      showEmailSent: false,
      valueType: {
        FULL_NAME: 'full_name',
        EMAIL: 'email',
        COMPANY: 'company',
        ADVERTISER_DOMAIN: 'advertiser_domain',
        PASSWORD: 'password',
        CONFIRM_PASSWORD: 'confirm_password',
      },
    };
  },
  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },

  mounted() {
    // clear cached IDs
    window.localStorage?.removeItem('plan/campaignId');
    window.localStorage?.removeItem('plan/planId');
    window.localStorage?.removeItem('campaignId');
  },

  created() {
    this.appId = this.$route.query.app;
  },
  methods: {
    async sendEmailVerification() {
      this.errorMsg = null;
      await axios
        .post(
          `${config.ADREADY_URL}/api/sendVerificationEmail?userId=${this.userId}&app=${this.appId}`
        )
        .catch((error) => {
          this.errorMsg = 'failed to send verify mail';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        });
      if (!this.errorMsg) {
        this.showEmailSent = true;
      }
      setTimeout(() => {
        this.showEmailSent = false;
      }, 3000);
    },
    onInputValueChange(val, type) {
      this.signUp[type] = val;
    },
    onKeyUp(val, e) {
      if (e.key && e.key.includes('Enter')) {
        this.onClickSignup();
      }
    },
    onSelectTnc(val) {
      this.tncAccepted = val.checked;
    },
    async onClickSignup() {
      // remove the 'redirect_uri' cookie which has been setup on full domain url.
      VueCookies.remove(COOKIE_NAME_REDIRECT_URI);

      this.errorMsg = null;
      this.focusVar = null;
      const valKeys = Object.keys(this.valueType);
      for (let i = 0; i < valKeys.length; i++) {
        const valType = this.valueType[valKeys[i]];
        if (isBlank(this.signUp[valType])) {
          this.errorMsg = `${valType?.split('_')?.join(' ')} cannot be blank.`;
          this.$refs[valType].focus();
          return;
        }
      }
      const pass = this.signUp[this.valueType.PASSWORD];
      const confirmPass = this.signUp[this.valueType.CONFIRM_PASSWORD];
      if (isBlank(pass) || pass.length < 6) {
        this.errorMsg = 'Password cannot be blank. Must be at least 6 characters.';
        this.$refs.password.focus();
        return;
      }
      const validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.signUp.email.match(validEmailRegex)) {
        this.errorMsg = 'Please enter valid email id';
        return;
      }

      const validDomainRegex = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;
      if (!this.signUp.advertiser_domain.match(validDomainRegex)) {
        this.errorMsg = 'Please enter valid domain';
        return;
      }

      if (!isEqual(pass, confirmPass)) {
        this.errorMsg = `Passwords do not match`;
        this.$refs.confirm_password.focus();
        return;
      }
      if (!this.tncAccepted) {
        this.errorMsg = `Please accept terms and conditions`;
        return;
      }
      this.isSignupLoading = true;
      axios
        .post(`${config.ADREADY_URL}/api/signUp`, {
          advertiserDomain: this.signUp.advertiser_domain,
          company: this.signUp.company,
          email: this.signUp.email,
          fullName: this.signUp.full_name,
          password: this.signUp.password,
          app: this.appId,
        })
        .then((resp) => {
          this.email = resp?.data.result.email || '';
          this.userId = resp?.data.result.id || '';
          this.success = true;
        })
        .catch((error) => {
          this.errorMsg = 'failed to signup the user.';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        });
      this.isSignupLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.login-block {
  max-width: 980px !important;
  left: 46% !important;
  & .loginimg {
    max-width: 480px !important;
    width: 480px !important;
    height: 470px !important;
  }
}
.login-info {
  margin: unset !important;
  &.signup-info {
    & .ftitle {
      line-height: 20px;
      margin-bottom: 25px;
    }
  }
}
.signup-cont {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  width: 460px;
}
.flip-container {
  background-color: #131619;
  width: 100vw !important;
  height: 100vh;
  margin-bottom: -40px;
}
.signup-info-row {
  display: flex;
  &-column1 {
    width: 200px;
  }
  &-column2 {
    width: 200px;
    margin-left: 20px;
    position: relative;
  }
}
.or {
  color: #9c9c9c !important;
}
.forgot-password {
  margin-top: -12px;
  margin-bottom: 20px;
  font-size: smaller;
  color: #9c9c9c !important;
  text-align: right;
}
.signin-text {
  color: var(--defaultprimarycolor);
  font-size: 13px;
  margin-left: 5px;
  font-weight: 600;
}
.alreadylogin-label {
  display: flex;
  font-size: 13px;
  color: #9c9c9c !important;
  position: absolute;
  top: 10px;
  right: 15px;
}
.tandcLabel {
  color: var(--defaultprimarycolor);
  font-size: 13px;
  margin-left: 5px;
  font-weight: 600;
}
.agree-label {
  display: flex;
  font-size: 13px;
  color: #9c9c9c !important;
  margin-bottom: 20px;
  margin-top: 10px;
}
::v-deep .form-input .form-input-field {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #dadada !important;
  background-color: #fff !important;
  color: #000 !important;
}
::v-deep .checkbox-btn {
  margin-right: 10px !important;
}
::v-deep .checkbox-btn svg.checkbox-inactive {
  color: #fff !important;
  height: 0.8em;
}

::v-deep .k-btn.btn-size-4 {
  position: relative;
  right: 19px;
  left: 0px !important;
  width: 420px;
  padding: 0 !important;
  background-color: var(--defaultprimarycolor) !important;
  font-size: 14px !important;
  // padding: 20px 18px !important;
  border-radius: 0 !important;
  border: 0px;
  cursor: pointer;
  appearance: none;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 0.15em !important;
  line-height: 1.2em;
  display: inline-block;
  font-weight: 400 !important;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 214px !important;
  user-select: none;
  span.label {
    color: #fff;
  }
  &:hover {
    background-color: #424242 !important;
    color: #fff !important;
  }
}
::v-deep .icon-wrap svg.fa-info-circle {
  color: #9c9c9c;
  font-size: 0.8em;
}
::v-deep .icon-wrap {
  position: relative;
  right: 38px;
  top: 3px;
}
::v-deep [data-tool-tip] {
  z-index: 999;
  padding: 5px 8px !important;
  font-weight: 500;
  margin: 0;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  width: 180px;
  color: #000 !important;
  background: var(--defaultprimarycolor) !important;
  border-radius: 4px;
  [data-popper-arrow]::before {
    background: var(--defaultprimarycolor) !important;
  }
}
.login-block .load-row {
  width: unset;
  min-height: unset;
  padding-top: unset;
  margin: auto;
}
.error {
  font-size: 13px;
  color: red;
}
.success {
  font-size: 13px;
  color: #4fb845;
}

.login-block-verify .login-info {
  margin: 0 auto !important;
  text-align: center;
  .f-resend-title {
    color: var(--defaultprimarycolor) !important;
    font-weight: 500;
    margin-top: 30px;
    font-size: 26px;
    margin-bottom: 30px;
  }
  .finfo {
    color: #8b8b8b;
    font-size: 14px;
    max-width: 410px;
    & a {
      font-weight: 600;
    }
    & a.link-color {
      color: var(--defaultprimarycolor) !important;
      display: inline;
    }
  }
  .mail-btn {
    margin: 0;
  }
  ::v-deep .k-btn.btn-size-4 {
    right: 0;
    margin-bottom: 35px;
    margin-top: 10px;
    width: 214px;
    border-radius: 3px !important;
  }
}

.login-block-verify {
  max-width: 920px;
  background: #fff !important;
  & .loginimg {
    max-width: 500px;
    width: 500px;
  }
}
.finfo {
  color: #8b8b8b;
  font-size: 14px;
  max-width: 355px;
  & a {
    font-weight: 600;
  }
  & a.link-color {
    color: var(--defaultprimarycolor) !important;
  }
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.femail {
  color: #8b8b8b;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 14px;
}
::v-deep .login-block-verify .login-info svg.svg-inline--fa {
  color: #daf0f3;
}
.sent-email-success {
  font-size: 16px;
  color: rgba(107, 207, 24);
  margin-bottom: 35px;
  height: 41px;
  .success-check-icon {
    color: rgba(107, 207, 24) !important;
    margin-left: 3px;
  }
}
</style>
